.locateButton {
  position: absolute;
  top: 80px;
  left: 2px;
  z-index: 999;
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: none;
  padding: 5px;
  background: #fff;
}

.menuButton {
  position: absolute;
  bottom: 80px;
  left: 2px;
  z-index: 999;
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: none;
  padding: 5px;
  background: #fff;
}

.locateActive {
  fill: red;
}

.locatedAnimation {
  width: 12px;
  height: 12px;
  border: 1px solid #fff;
  border-radius: 50%;
  background: #2a93ee;
  animation: borderPulse 2s infinite;
}

.iconPulse {
  animation: borderPulse 2s infinite;
  border-radius: 50%;
}

@keyframes borderPulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}
