html,
body,
#root,
.wraper-box {
  height: 100%;
}

.leaflet-interactive {
  outline: none;
}

#leaflet-map {
  width: 100vw;
  height: 100%;
}

#leaflet-map.leaflet-container {
  font-family: "Roboto", sans-serif;
}

#leaflet-map .leaflet-container a.leaflet-popup-close-button {
  top: -4px;
  right: -4px;
  background-color: #fff !important;
  border-radius: 50%;
}

#leaflet-map .leaflet-popup-content {
  margin: 0 0 0 0;
  width: 200px !important;
}

#leaflet-map .leaflet-popup-content-wrapper {
  border-radius: 5px;
  padding: 0 0 0 0;
  overflow: hidden;
}

.track-wrap {
  padding: 0 0 10px 0;
}

.wraper-box {
  display: flex;
  height: 100%;
}

.menu-button {
  /*background-color: #ffffff;
    border: none;
    display: block;
    width: 48px;
    height: 48px;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10005;
    border-radius: 50%;
    box-shadow: 0 2px 5px -1px rgba(0,0,0,.1), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSI1MTIiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiB3aWR0aD0iNTEyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjx0aXRsZS8+PGxpbmUgc3R5bGU9ImZpbGw6bm9uZTtzdHJva2U6IzAwMDtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbWl0ZXJsaW1pdDoxMDtzdHJva2Utd2lkdGg6NDhweCIgeDE9Ijg4IiB4Mj0iNDI0IiB5MT0iMTUyIiB5Mj0iMTUyIi8+PGxpbmUgc3R5bGU9ImZpbGw6bm9uZTtzdHJva2U6IzAwMDtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbWl0ZXJsaW1pdDoxMDtzdHJva2Utd2lkdGg6NDhweCIgeDE9Ijg4IiB4Mj0iNDI0IiB5MT0iMjU2IiB5Mj0iMjU2Ii8+PGxpbmUgc3R5bGU9ImZpbGw6bm9uZTtzdHJva2U6IzAwMDtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbWl0ZXJsaW1pdDoxMDtzdHJva2Utd2lkdGg6NDhweCIgeDE9Ijg4IiB4Mj0iNDI0IiB5MT0iMzYwIiB5Mj0iMzYwIi8+PC9zdmc+);
    background-repeat: no-repeat;
    background-size: 70%;
    background-position: center center;*/
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fff;
}
